import AutoNumeric from "autonumeric"

$(function () {
  var updateEuroValues = function () {
    $('input.national').each(function () {
      var $this = $(this)
      var eurElement = $this.parents('.currency-root').find('.eur')
      var nationalValue = AutoNumeric.getNumericString(this)
      if (!nationalValue) {
        return
      }

      $.get(Routes.exchange_rates_national_to_eur_path({
        value: nationalValue,
        currency_id: $('.exchange-currency-id').val(),
        date: $('.exchange-rate-date').val(),
      }), function (data) {
        eurElement.html(data + ' €')
        eurElement.data('value', data.replace(',', '.'))
      })
    })
  }

  $(document).on('change', 'input.national, .exchange-rate-date', updateEuroValues)
  updateEuroValues()
})

