$(function () {
  var onBeneficiaryChanged = function () {
    $.get(Routes.admin_users_employees_for_beneficiary_path({
      beneficiary_id: $('#user_beneficiary_id').val()
    }), function (data) {
      var $employeeSelect = $('#user_employee_id')
      $employeeSelect.empty().append(new Option('', null))
      for (var i in data) {
        $employeeSelect.append(new Option(data[i].name, data[i].id))
      }
      $employeeSelect.selectpicker('refresh')
    })
  }
  $('body').on('change', '#user_beneficiary_id', onBeneficiaryChanged)

  var onIsEmployeeChanged = function () {
    var checked = $(this).is(':checked')
    var $select = $('select[data-employee-select]')
    $select.prop('disabled', !checked)
    if (!checked) {
      $('option:selected', $select).prop('selected', false)
    }
    $select.selectpicker('refresh')
  }
  var employeeInputSelector = 'input[data-is-employee]'
  $('body').on('change', employeeInputSelector, onIsEmployeeChanged)
  $(employeeInputSelector).trigger('change')
})
