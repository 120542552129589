import AutoNumeric from "autonumeric"

$(function () {
  if (!$('form[data-record-class="beneficiary"]').length) {
    return
  }

  $('input[data-money]').change(function () {
    var sum = $(this)
      .parents('tr')
      .find('input[data-money]')
      .map(function () {
        return AutoNumeric.getNumber(this)
      })
      .get()
      .reduce((acc, val) => acc + val, 0)
    var sumElement = $(this).parents('tr').find('span[data-sum]').get(0)
    AutoNumeric.set(sumElement, sum)
  })
})
