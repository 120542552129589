$(function () {
  var $period = $('#q_period')
  var updateYearOrMonth = function () {
    var period = $period.val()
    if (period === 'year') {
      $('input#q_year').prop('disabled', false)
      $('input#q_year_and_month').prop('disabled', true)
    } else {
      $('input#q_year').prop('disabled', true)
      $('input#q_year_and_month').prop('disabled', false)
    }
  }
  $period.change(updateYearOrMonth)
  updateYearOrMonth()
})
