import AutoNumeric from 'autonumeric'

$.fn.clearForm = function () {
  return this.each(function () {
    var type = this.type, tag = this.tagName.toLowerCase()
    if (tag == 'form')
      return $(':input', this).clearForm()
    if (type == 'text' || type == 'password' || tag == 'textarea')
      this.value = ''
    else if (type == 'checkbox' || type == 'radio')
      this.checked = false
    else if (tag == 'select')
      this.selectedIndex = -1
  })
};

(function ($) {
  $.renderMoney = function (data, type, row) {
    return $.number(data, 2, ',', ' ')
  }
  $.elma = {
    enhanceInputs: function (root) {
      const $root = $(root)

      $root
        .find('select:not([data-none-selected-text])')
        .data('noneSelectedText', 'Nothing selected')

      $root
        .find('select:not([data-raw])')
        .data('liveSearch', 'true')
        .selectpicker()

      $root
        .find('*[data-money]')
        .each(function () {
          new AutoNumeric(
            this,
            {
              "currencySymbol": "",
              "digitGroupSeparator": " ",
              "decimalCharacter": ",",
              minimumValue: "-9999999999999.99",
              modifyValueOnWheel: false
            }
          )
        })


      $root
        .find('*[data-rate]')
        .each(function () {
          new AutoNumeric(
            this,
            {
              "currencySymbol": "",
              "digitGroupSeparator": " ",
              "decimalCharacter": ",",
              "decimalPlacesOverride": 4,
              modifyValueOnWheel: false
            }
          )
        })

      $root
        .find('*[data-hours]')
        .each(function () {
          new AutoNumeric(
            this,
            {
              "currencySymbol": "",
              "digitGroupSeparator": " ",
              "decimalCharacter": ",",
              "decimalPlacesOverride": 2,
              modifyValueOnWheel: false
            }
          )
        })

      $root.find('*[data-boolean]').each(function () {
        var result = $(this).html() == 'true' ? 'yes' : 'no'
        $(this).html(result)
      })

      $root.find('.datepicker').each(function () {
        var options = {language: $('body').data('locale'), format: 'yyyy-mm-dd', zIndexOffset: 2000}
        if ($(this).data('viewmode') == 'months') {
          options.maxViewMode = 2
          options.minViewMode = 1
          options.format = 'yyyy-mm'
        }
        $(this).datepicker(options)
      })

      $root.find('button[data-post-button]').click(function () {
        var $form = $(document.createElement('form'))
          .attr("action", $(this).data('post-url'))
          .attr('method', 'POST')
        var input = $("<input>")
          .attr("type", "hidden")
          .attr("name", $("meta[name='csrf-param']").attr('content'))
          .val($("meta[name='csrf-token']").attr('content'))
        $form.append($(input))
        $form.appendTo(document.body).submit()
        return false
      })
    },
    getProjectId: function () {
      return $('#project_id').val()
    }
  }
})(jQuery)

$(function () {
  // Cocoon fix until this PR https://github.com/nathanvda/cocoon/pull/575 gets merged
  $('.remove_fields.existing.destroyed').each(function (i, obj) {
    var $this = $(this), wrapper_class = $this.data('wrapper-class') || 'nested-fields'
    $this.closest('.' + wrapper_class).hide()
  })

  var locale = $('body').data('locale')

  $('button:reset').click(function () {
    $(this).parents('form').clearForm()
    return false
  })
  $.elma.enhanceInputs(document)
  $(document).on('cocoon:after-insert', (event, item) => $.elma.enhanceInputs(item))
  Routes.configure({
    default_url_options: {locale: locale}
  })

  $('[data-toggle="popover"]').popover({ sanitize: false })
  $(document).on('click', 'button[data-dismiss-popover]', function(e) {
    $('[data-toggle="popover"]').trigger('click')
  })

  $('#filter').appendTo('.search-form')
  $('*[data-record-form-submit]').click(function () {
    $('.record-form').submit()
  })
  $('*[data-remote-filter-clear]').click(function () {
    $('button:reset').click()
    $('form.search-form').submit()
  })
  if ($('form.record-form[data-readonly=true]').length > 0) {
    $('form.record-form input, form.record-form select, form.record-form .btn, *[data-record-form-submit]')
      .attr("disabled", true)
    $('form.record-form select:not([data-raw])').selectpicker('refresh')
  }


  var checkFileSize = function (e) {
    var inputs = document.querySelectorAll('input[type=file]'), i

    // Can't use forEach yet because Safari 9 doesn't support it on NodeList
    for (i = 0; i < inputs.length; ++i) {
      var input = inputs[i]
      if (input.files === undefined) {
        return
      }

      if (input.files[0].size > 25000000) {
        e.preventDefault()
        alert('Documents cannot be larger than 25 megabytes.')
      }
    }
  }

  $(document).on('submit', '.record-form', function (e) {
    try {
      checkFileSize(e)
    } catch (error) {
      // TODO this probably won't have enough time to send to Rollbar, but let's leave it here
      try {
        Rollbar.log('Error while checking file size', error)
      } catch (error2) {
      }
    }
  })
})

// fixes "back button" misbehavior of Safari
$(window).bind("pageshow", function (event) {
  if (event.originalEvent.persisted) {
    window.location.reload()
  }
})

