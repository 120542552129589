/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'jquery'
import 'bootstrap-sass/assets/javascripts/bootstrap'
import 'bootstrap-select/dist/js/bootstrap-select'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.pl'
import 'autonumeric'
import '@nathanvda/cocoon'

window.Routes = require('routes.js.erb')

import '../src/elma.js.erb'
import '../src/currency_converter.js.erb'
import '../src/employees'
import '../src/jquery.number'
import '../src/time_tracking_sheets'
import '../src/simple_time_sheets'
import '../src/users'
import '../src/project/agreements.js.erb'
import '../src/project/beneficiaries'
import '../src/project/employee_personal_costs'
import '../src/project/hcf.js.erb'
import '../src/project/invoices.js.erb'
import '../src/project/orders.js.erb'
import '../src/project/payment_share_fields'

import Rails from 'rails-ujs'

import '../stylesheets/application.css.sass'

Rails.start()
