import AutoNumeric from "autonumeric"

$(function() {
  if (!$('form[data-record-class="time_tracking_sheet"]').length) {
    return
  }

  $('button[data-edit-sign]').click(function(e) {
    e.preventDefault()
    $('#redirect_to').val($(this).data('redirect-to'))
    $('.record-form').submit()
  })

  $('button[data-sign]').click(function(e) {
    e.preventDefault()
    $('#new_signature_params').submit()
  })

  $('button[data-new-action]').click(function(e) {
    const newId = new Date().getTime().toString()

    const $this = $(this)
    const unescaped = $('<div />').html($this.data('template')).text().replaceAll('$ID', newId)
    const newRow = $(unescaped)
    $.elma.enhanceInputs(newRow)
    newRow.insertBefore($this.parents('tr'))
  })

  $(document).on('click', 'button[data-remove-action]', function(e) {
    const $this = $(this)

    const row = $this.parents('tr')
    const inputs = row.find('input[data-hours]')
    inputs.each(function() {
      const $this = $(this)
      const name = $this.attr('name').replaceAll('worked_hours', '_destroy')
      $(`<input type='hidden' name=${name} value='1'>`).insertAfter($this)
    })
    row.hide()
    inputs.trigger('input')
  })

  $(document).on('change', 'select[data-action-select]', function(e) {
    const $this = $(this)
    $this.parents('tr').find("input[type='hidden'][data-action-id]").val($this.val())
  })

  const countTotal = (elements) =>
    elements
      .get()
      .map(el => AutoNumeric.getNumber(el))
      .reduce((acc, n) => acc + n, 0)

  $(document).on('input', 'input[data-hours]', function() {
    const $this = $(this)

    const trackedDayIndex = $this.parents('td').data('tracked-day-index')
    const colTotal = countTotal($(`td[data-tracked-day-index='${trackedDayIndex}']:visible input[data-hours]`))
    const colTotalElement = $(`*[data-tracked-day-total-index='${trackedDayIndex}']`).get(0)
    AutoNumeric.set(colTotalElement, colTotal)

    const projectId = $this.data('project-id')
    if (projectId) {
      const projectDayTotal = countTotal($(`td[data-tracked-day-index='${trackedDayIndex}']:visible input[data-hours][data-project-id='${projectId}']`))
      const projectDayTotalElement = $(`*[data-project-total-day-index='${trackedDayIndex}'][data-project-id='${projectId}']`).get(0)
      AutoNumeric.set(projectDayTotalElement, projectDayTotal)

      const projectTotal = countTotal($(`td:visible input[data-hours][data-project-id='${projectId}']`))
      const projectTotalElement = $(`*[data-project-total][data-project-id='${projectId}']`).get(0)
      AutoNumeric.set(projectTotalElement, projectTotal)
    }

    const row = $this.parents('tr')
    const rowTotal = countTotal(row.find('td:visible input[data-hours]'))
    const rowTotalElement = row.find('*[data-row-total]').get(0)
    AutoNumeric.set(rowTotalElement, rowTotal)

    const total = countTotal($('input[data-hours]:visible'))
    const totalElement = $('*[data-total]').get(0)
    AutoNumeric.set(totalElement, total)
  })
})
