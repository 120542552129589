$(function () {
  var beneficiarySelect = $('#beneficiary_id')
  var currencySelect = $('#report_currency_code')

  var updateCurrency = function () {
    var currencyCode = beneficiarySelect.find('option:selected').data('currencyCode')
    if (currencyCode && currencyCode !== 'eur') {
      currencySelect.find('option[value=' + currencyCode + ']').attr('disabled', false)
    } else {
      currencySelect.find('option[value!=eur]').attr('disabled', true)
    }
    currencySelect.selectpicker('refresh')
  }

  beneficiarySelect.change(updateCurrency)
  updateCurrency()
})
