$(function () {
  if (!$('form[data-record-class="order"]').length) {
    return
  }

  var updatePartValues = function () {
    if ($('#order_granted_in_parts').is(":checked")) {
      $('#part-values-section').show()
    } else {
      $('#part-values-section').hide()
    }
  }
  $('#order_granted_in_parts').change(updatePartValues)
  updatePartValues()
})
