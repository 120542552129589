import AutoNumeric from "autonumeric"

$(function () {
  console.log('invoice.js')

  if (!$('form[data-record-class="invoice"]').length) {
    return
  }

  var projectId = $.elma.getProjectId()

  function getCategoryType() {
    return $('#invoice_expense_category_id').find(':selected').data('type')
  }

  var $invoicePaidOn = $('#invoice_paid_on')
  var $invoiceAccountNumber = $('#invoice_account_number')
  var updateRecordStatus = function () {
    var paidOn = $invoicePaidOn.val()
    var accountNumber = $invoiceAccountNumber.val()
    $.get(Routes.project_invoices_record_status_path({
        project_id: projectId,
        paid_on: paidOn,
        account_number: accountNumber
      }),
      function (data) {
        $('#invoice_record_status').selectpicker('val', data)
      })
  }
  $invoiceAccountNumber.change(updateRecordStatus)
  $invoicePaidOn.change(updateRecordStatus)
  updateRecordStatus()

  var updateAdditionalData = function () {
    $('#additional-data>span').hide()
    var expenseCategoryId = $('#invoice_expense_category_id').val()
    if (!expenseCategoryId) {
      return
    }

    var categoryType = getCategoryType()
    if (categoryType) {
      $('#invoice-additional-info-' + categoryType.replace(/_/g, '-')).show()
    }

    var $eligibleValue = $('.invoice_eligible_value_gross input[data-money],.invoice_eligible_value_net input[data-money]')
    if (categoryType === 'asset') {
      $eligibleValue.prop('readonly', true).val('')
      $('.depreciation_months_falling_in_period,.invoice_depreciation_value_net,.invoice_depreciation_value_gross').show()
      calculateDepreciation()
    } else {
      $eligibleValue.prop('readonly', false)
      $('.depreciation_months_falling_in_period,.invoice_depreciation_value_net,.invoice_depreciation_value_gross').hide()
      $('.invoice_gross_value input[data-money],.invoice_net_value input[data-money]').trigger('change')
    }
  }
  $('#invoice_expense_category_id').change(updateAdditionalData)
  updateAdditionalData()

  var setActionsAndPaymentShares = function () {
    var agreementId = $('#invoice_agreement_id').val()
    if (!agreementId) {
      return
    }
    $.get(Routes.project_agreement_path(projectId, agreementId),
      function (data) {
        if ($('#new_invoice').length) {
          $('#action-shares').empty()
          $('#payment-shares').empty()
          data.action_shares.forEach(function (actionShare) {
            $('a.add_fields[data-associations=action_shares]').trigger('click')
            $('#action-shares select').last().selectpicker('val', actionShare.action_id)
            AutoNumeric.set($('#action-shares input[data-money]').get(0), actionShare.action_value_amount)
          })
          data.payment_shares.forEach(function (paymentShare) {
            $('a.add_fields[data-associations=payment_shares]').trigger('click')
            var shareElement = $('#payment-shares .nested-fields').last()
            shareElement.find('select').first().selectpicker('val', paymentShare.beneficiary_id)
            AutoNumeric.set(shareElement.find('input[data-money]').get(0), paymentShare.share_value_amount)
          })
          $('#invoice_description').val(data.subject_description)
          AutoNumeric.set('#invoice_net_value_eur', data.net_value_amount)
          AutoNumeric.set('#invoice_net_value_national', data.net_value_amount)
          AutoNumeric.set('#invoice_gross_value_eur', data.gross_value_amount)
          AutoNumeric.set('#invoice_gross_value_national', data.gross_value_amount)
          if (getCategoryType() !== 'asset') {
            AutoNumeric.set('.invoice_eligible_value_net input[data-money]', data.net_value_amount)
            AutoNumeric.set('.invoice_eligible_value_gross input[data-money]', data.gross_value_amount)
          }
        }

        $('#invoice_invoice_contractor_info_attributes_contractor_name').val(data.counterparty_name)
        $('#invoice_invoice_contractor_info_attributes_provided_from').val(data.signed_on)
        $('#invoice_invoice_contractor_info_attributes_provided_to').val(data.completed_on)
      })
  }
  $('#invoice_agreement_id').change(setActionsAndPaymentShares)

  function setSharesValue(value) {
    var actionShareInputs = $('#action-shares input[data-money]:visible')
    if (actionShareInputs.length === 1) {
      AutoNumeric.set(actionShareInputs.get(0), value)
    }
    var paymentShareInputs = $('#payment-shares input[data-money]:visible')
    if (paymentShareInputs.length === 1) {
      AutoNumeric.set(paymentShareInputs.get(0), value)
    }
  }

  $('.invoice_gross_value input[data-money]').change(function () {
    if (getCategoryType() === 'asset') {
      return
    }

    var value = AutoNumeric.getNumber(this)
    $('.invoice_eligible_value_gross input[data-money]').each(function () {
      AutoNumeric.set(this, value)
    })
    setSharesValue(value)
  })

  $('.invoice_net_value input[data-money]').change(function () {
    if (getCategoryType() === 'asset') {
      return
    }

    var value = AutoNumeric.getNumber(this)
    $('.invoice_eligible_value_net input[data-money]').each(function () {
      AutoNumeric.set(this, value)
    })
  })

  function calculateDepreciation() {
    var categoryType = getCategoryType()
    if (categoryType !== 'asset') {
      return
    }

    var depreciationTotalMonths = Number($('#invoice_invoice_asset_info_attributes_depreciation_total_months').val())
    var issuedOn = $('#invoice_issued_on').val()
    var netValue = $('.invoice_net_value input[data-money]').val()
    var grossValue = $('.invoice_gross_value input[data-money]').val()

    if (!depreciationTotalMonths || !issuedOn || !netValue || !grossValue) {
      return
    }

    $.get(Routes.project_invoices_depreciation_path({
        project_id: projectId,
        issued_on: issuedOn,
        net_value: netValue,
        gross_value: grossValue,
        depreciation_total_months: depreciationTotalMonths
      }),
      function(data) {
        $('.eligible-value').each(function(index) {
          var row = data[index]
          var $this = $(this)

          $this.find('.depreciation_months_falling_in_period input').val(row.depreciation_months_falling_in_period)
          setElementValue($this, '.invoice_depreciation_value_net', row.depreciation_net)
          setElementValue($this, '.invoice_depreciation_value_gross', row.depreciation_gross)
          setElementValue($this, '.invoice_eligible_value_net', row.eligible_value_net)
          setElementValue($this, '.invoice_eligible_value_gross', row.eligible_value_gross)
        })
      })
  }

  function setElementValue(parent, klass, value) {
    var $element = parent.find(klass + ' input[data-money]')
    var element = $element.get(0)
    AutoNumeric.set(element, value)
    $element.trigger('change')
  }

  $('#invoice_invoice_asset_info_attributes_depreciation_total_months').change(calculateDepreciation)
  $('#invoice_issued_on').change(calculateDepreciation)
  $('#invoice_net_value_eur').change(calculateDepreciation)
  $('#invoice_gross_value_eur').change(calculateDepreciation)
  calculateDepreciation()
})
