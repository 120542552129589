import AutoNumeric from "autonumeric"
$(function () {
  if (!$('form[data-record-class="agreement"]').length) {
    return
  }

  var setActionsAndPaymentShares = function () {
    $('#action-shares').empty()
    $('#payment-shares').empty()
    var orderId = $('#agreement_order_id').val()
    $.get(Routes.project_order_path($.elma.getProjectId(), orderId),
      function (data) {
        data.action_shares.forEach(function (actionShare) {
          $('a.add_fields[data-associations=action_shares]').trigger('click')
          $('#action-shares select').last().selectpicker('val', actionShare.action_id)
        })
        $('a.add_fields[data-associations=payment_shares]').trigger('click')
        $('#payment-shares select').first().selectpicker('val', data.order_conductor_id)
      })
  }
  if ($('#new_agreement').length) {
    $('#agreement_order_id').change(setActionsAndPaymentShares)
  }

  $('#agreement_gross_value_eur, #agreement_gross_value_national').change(function () {
    var actionShareInputs = $('#action-shares input[data-money]:visible')
    if (actionShareInputs.length === 1) {
      AutoNumeric.set(actionShareInputs[0], AutoNumeric.getNumber(this))
    }
    var paymentShareInputs = $('#payment-shares input[data-money]:visible')
    if (paymentShareInputs.length === 1) {
      AutoNumeric.set(paymentShareInputs[0], AutoNumeric.getNumber(this))
    }
  })
})
