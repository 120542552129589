import AutoNumeric from "autonumeric"

$(function() {
  if (!$('form[data-record-class="employee"]').length) {
    return
  }

  const handleContractType = function() {
    var contractType = $('#employee_contract_type').val()

    $('div.employee_employee_contract_type').toggle(contractType === 'Employee')

    const timeTrackingMode = $('#employee_time_tracking_mode')
    const dailyRateAndEngagementRate = $('input[data-planned-daily-rate],input[data-engagement-rate]')

    if (contractType === 'Volunteer') {
      timeTrackingMode.val('Standard').attr('disabled', true).selectpicker('refresh')
      dailyRateAndEngagementRate.attr('disabled', true)

      const organizationId = $('#employee_organization_id').val()
      if (organizationId) {
        $.get(
          Routes.project_employees_volunteer_planned_daily_rate_path({
            organization_id: organizationId,
            project_id: $.elma.getProjectId()
          }),
          data => {
            const plannedDailyRate = parseFloat(data)
            $('input[data-planned-daily-rate]').each(function() {
              AutoNumeric.set(this, plannedDailyRate)
            })
          }
        )
      }
    } else {
      timeTrackingMode.attr('disabled', false).selectpicker('refresh')
      dailyRateAndEngagementRate.attr('disabled', false)
    }
  }
  $('#employee_contract_type').change(handleContractType)
  handleContractType()
  $('#employee-projects').on('cocoon:after-insert', handleContractType)
  $('#employee_organization_id').change(handleContractType)

  const handleWorkedHoursMode = function() {
    const workedHoursMode = $('#employee_worked_hours_mode').val()
    $('#employee-work-hours-shares-fieldset').toggle(workedHoursMode === 'Variable')
  }
  handleWorkedHoursMode()
  $('#employee_worked_hours_mode').change(handleWorkedHoursMode)
  $('#work-hours-shares').on('cocoon:after-insert', handleWorkedHoursMode)
})
