$(function () {
  var setCofinancierVisibility = function () {
    var cofinancier = $(this).parents('.nested-fields').find('select[data-cofinancier]')
    var cofinancierWrapper = cofinancier.parents('.col-sm-2')
    if ($(this).val() != 'Cofinancier') {
      cofinancier.selectpicker('deselectAll')
      cofinancierWrapper.hide()
    } else {
      cofinancierWrapper.show()
    }
  }
  $('body').on('change', 'select[data-contribution-source]', setCofinancierVisibility)
  $('select[data-contribution-source]').each(function (index, element) {
    setCofinancierVisibility.call(element)
  })

})
