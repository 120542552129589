$(function() {
  if (!$('#simple_time_sheets').length) {
    return
  }

  $('#employee_id').change(function() {
    const $newSimpleTimeSheet = $('#new_simple_time_sheet')
    const newHref = $newSimpleTimeSheet.attr('href').replace(/employee_id=(.*)/, `employee_id=${$(this).val()}`)
    $newSimpleTimeSheet.attr('href', newHref).removeClass('disabled')
  })
})
